<template>
<Transition name="slide-fade" appear>
  <div class="px-2 sm:px-6 lg:px-4 text-t3">
    <h2 class="text-2xl font-bold sm:text-3xl">
				Settings
			</h2>
    <div class="shadow-t3 mt-4 bg-white p-6 md:px-14 rounded">
      <div>
        <ul class="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <a href="#tabs-home" class="nav-link block font-medium text-base leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active" id="tabs-home-tab" data-bs-toggle="pill" data-bs-target="#tabs-home" role="tab" aria-controls="tabs-home" aria-selected="true">General</a>
            </li>
            <li class="nav-item" v-if="scopeCheck('view_roles')" role="presentation">
                 <a href="#tabs-profile" class="nav-link block font-medium text-base leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="tabs-profile-tab" data-bs-toggle="pill" data-bs-target="#tabs-profile" role="tab" aria-controls="tabs-profile" aria-selected="false">Roles & Permissions</a>
            </li>
            <li class="nav-item" role="presentation">
                 <a href="#tabs-tasks" class="nav-link block font-medium text-base leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="tabs-tasks-tab" data-bs-toggle="pill" data-bs-target="#tabs-tasks" role="tab" aria-controls="tabs-tasks" aria-selected="false">Tasks</a>
            </li>
            <li class="nav-item" role="presentation">
                 <a href="#tabs-addresses" class="nav-link block font-medium text-base leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="tabs-addresses-tab" data-bs-toggle="pill" data-bs-target="#tabs-addresses" role="tab" aria-controls="tabs-addresses" aria-selected="false">Addresses</a>
            </li>
            <li class="nav-item" role="presentation">
                 <a href="#tabs-discount" class="nav-link block font-medium text-base leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="tabs-discount-tab" data-bs-toggle="pill" data-bs-target="#tabs-discount" role="tab" aria-controls="tabs-discount" aria-selected="false">Organization & Discounts</a>
            </li>
            <li class="nav-item" role="presentation">
                 <a href="#tabs-license" class="nav-link block font-medium text-base leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="tabs-license-tab" data-bs-toggle="pill" data-bs-target="#tabs-license" role="tab" aria-controls="tabs-license" aria-selected="false">License Types</a>
            </li>
        </ul>
        <div class="tab-content" id="tabs-tabContent">
            <!-- General Settings Start -->
            <div class="tab-pane fade show active" id="tabs-home" role="tabpanel" aria-labelledby="tabs-home-tab">
                <p>{{CompanySettings}}</p>
                <div v-if="scopeCheck('edit_settings')" class=" justify-end my-2 flex ">
                    <div class="form-check text-center form-switch">
                        <input v-model="editSet" :true-value="false" :false-value="true" class="form-check-input appearance-none w-9  rounded-full  h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="EditSettings" checked>
                        <label class="pt-2 form-check-label block text-t3-mutedText text-sm"  for="EditSettings">Edit Settings</label>
                    </div>
                </div>
                <!-- Control Start -->
                <div class="flex justify-start mb-4">
                    <div class="mb-3 xl:w-full">
                        <label for="exampleFormControlInput1" class="form-label inline-block mb-2 text-t3">Assessment Duration</label>
                        <div class=" flex space-x-3 items-center">
                            <input type="number" :disabled="editSet" class="form-control block w-32 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="examDuration" v-model="localSettings.assessment_duration" />
                            <div class="text-sm text-t3-mutedText mt-1">Example: 60 min for 1 hour</div>
                        </div>                 
                    </div>
                </div>
                <!-- Control End -->
                                <!-- Control Start -->
                <div class="flex justify-start mb-4">
                    <div class="mb-3 xl:w-full">
                        <label for="priceStd" class="form-label inline-block mb-2 text-t3">Assessment Default Pricing</label>
                        <div class=" flex space-x-3 items-center">
                            <input type="number" :disabled="editSet" class="form-control block w-32 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="priceStd" v-model="localSettings.standard_price" />
                            <div class="text-sm text-t3-mutedText mt-1">Example: 6000 dollars standard</div>
                        </div>                 
                    </div>
                </div>
                <!-- Control End -->
                <!-- Control Start -->
                <div class="flex justify-start mb-4">
                    <div class="mb-3 xl:w-full">
                        <label for="CloseAfter" class="form-label inline-block mb-2 text-gray-700">Lock the assessment after:</label>
                        <div class=" flex space-x-3 items-center">
                            <input type="number" :disabled="editSet" class="form-control block w-32 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="CloseAfter" v-model="localSettings.time_to_close_assessment" placeholder="60"/>
                            <div class="text-sm text-t3-mutedText mt-1">This will disable editing option after the specified time Example: 24 hour or 48 hours</div>  
                        </div>                
                    </div>
                </div>
                <!-- Control End -->
                <!-- Control Start -->
                <div class="flex justify-start mb-4">
                    <div class="mb-3 xl:w-full">
                        <label for="CloseAfter" class="form-label inline-block mb-2 text-gray-700">Lock disputes after:</label>
                        <div class=" flex space-x-3 items-center">
                            <input type="number" :disabled="editSet" class="form-control block w-32 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="CloseAfter" v-model="localSettings.allow_customer_to_raise_dispute_within" placeholder="2"/>
                            <div class="text-sm text-t3-mutedText mt-1">This will disable the customer raise dispute option after the specified time Example: 1 day or 5 days</div>  
                        </div>                
                    </div>
                </div>
                <!-- Control End -->
                <!-- Control Start -->
                <div class="flex justify-start mb-4">
                    <div class="mb-3 xl:w-full">
                        <label for="exampleFormControlInput1" class="form-label inline-block mb-2 text-gray-700">Customer ID Prefix</label>
                        <div class=" flex space-x-3 items-center">
                            <input type="text" :disabled="editSet" class="form-control block w-32 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border uppercase border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" v-model="localSettings.prefix" id="Prefix" placeholder="t3"/>
                            <div class="text-sm text-t3-mutedText mt-1">Customize customers ID with your prefix Example: t3-1234</div>
                        </div>                
                    </div>
                </div>
                <!-- Control End -->
                 <!-- Control Start -->
                 <div class="flex justify-start mb-4">
                    <div class="mb-3 xl:w-full">
                        <label for="exampleFormControlInput1" class="form-label inline-block mb-2 text-gray-700">Weekends</label>
                        <div class=" flex space-x-3 items-center">
                            <input type="text" :disabled="editSet" class="form-control block w-32 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border capitalize border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" v-model="localSettings.weekends" id="weekend" placeholder="Saturday,Sunday"/>
                            <div class="text-sm text-t3-mutedText mt-1">Choose weekends to be ignored when creating bulk slots. Separate by commas with no spaces. Example: Saturday,Sunday,Thursday</div>
                        </div>                
                    </div>
                </div>
                <!-- Control End -->
                <!-- Control Start -->
                    <div class="mb-4 pb-4 form-check flex form-switch">
                        <input v-model="localSettings.allow_customer_to_view_certificate" :disabled="editSet" class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="CertificateView" checked>
                        <label class="pl-7 form-check-label inline-block text-t3 " for="CertificateView">Allow customers to view the certificate</label>
                    </div>
                <!-- Control End -->
                <!-- Control Start -->
                    <div class="mb-4 pb-4 form-check flex form-switch ">
                        <input v-model="localSettings.show_sample_before_completion" :disabled="editSet" class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="ShowSample" checked>
                        <label class="pl-7 form-check-label inline-block text-t3 " for="ShowSample">Show the assigned sample questions before the assessment completion</label>
                    </div>
                <!-- Control End -->
                <!-- Control Start -->
                    <div class="mb-4 pb-4 form-check flex form-switch ">
                        <input v-model="localSettings.show_reports_to_assessors" :disabled="editSet" class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="ShowReports" checked>
                        <label class="pl-7 form-check-label inline-block text-t3 " for="ShowReports">Show reports between assessor</label>
                    </div>
                <!-- Control End -->
                <!-- Control Start -->
                    <div class="flex justify-start mb-4 ">
                        <div class="mb-3 xl:w-full">
                                <label for="compiance" class="form-label inline-block mb-2 text-gray-700">External Dispute Responsible Personnel:</label>
                                <div class=" flex space-x-3 items-center">
								<select id="compiance" :disabled="editSet" v-model="localSettings.external_dispute" :class="{'disdrop' : editSet}" class="form-control block w-1/2 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"> 
									<option disabled :value="null">Select Assignee for Externals</option>
                                    <option v-for="customer in staffOnly"  :value="customer.id" :key="customer.id">{{customer.full_name}}</option>
								</select>
                                </div>
                            </div>
                    </div>
                    <div class="flex justify-start mb-4 ">
                        <div class="mb-3 xl:w-full">
                                <label for="non-compiance" class="form-label inline-block mb-2 text-gray-700">Internal Dispute Responsible Personnel:</label>
                                <div class=" flex space-x-3 items-center">
								<select id="non-compiance" :disabled="editSet" v-model="localSettings.internal_dispute" :class="{'disdrop' : editSet}" class="form-control block w-1/2 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"> 
									<option disabled :value="null">Select Assignee for Internals</option>
                                    <option v-for="customer in staffOnly"  :value="customer.id" :key="customer.id">{{customer.full_name}}</option>
								</select>
                                </div>
                            </div>
                    </div>
                    <div class="flex justify-start mb-4 ">
                        <div class="mb-3 xl:w-full">
                                <label for="non-compiance" class="form-label inline-block mb-2 text-gray-700">Dispute Final Approver:</label>
                                <div class=" flex space-x-3 items-center">
								<select id="non-compiance" :disabled="editSet" v-model="localSettings.dispute_final_approver" :class="{'disdrop' : editSet}" class="form-control block w-1/2 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"> 
									<option disabled :value="null">Select Dispute Final Approver</option>
                                    <option v-for="customer in staffOnly"  :value="customer.id" :key="customer.id">{{customer.full_name}}</option>
								</select>
                                </div>
                            </div>
                    </div>
                    <div class="flex jstify-end">
                        <button type="button" v-if="!editSet" @click="updateSettings" class="inline-block py-2.5 font-medium text-t3-secondary text-base ">Save Changes</button>
                    </div>
                    
                <!-- Control End -->
                
            </div>
            <!-- General Settings End -->

            <!-- Roles & Permissions Start -->
            <div class="tab-pane fade" id="tabs-profile" v-if="scopeCheck('view_roles') " role="tabpanel" aria-labelledby="tabs-profile-tab">
                <div v-if="!userList" class="bg-white grid gap-4 grid-cols-1 sm:grid-cols-4" style="min-height: calc(80vh - 148px);">
                    <div class="border-r border-gray-100">
                        <div class="flex p-4">
                            <div class="bg-gray-200 w-full flex justify-center rounded-md">
                                <button @click="userList=false" :class="!userList ? 'text-t3 bg-white py-1 px-2 2xl:px-8 my-1 rounded-md' : 'text-gray-300'" class=" 2xl:text-lg">Roles</button>
                                <button @click="userList=true" :class="userList ? 'text-t3 bg-white py-1 px-2 2xl:px-8 my-1 rounded-md' : 'text-gray-700 py-1 px-2 2xl:px-8'" class="2xl:text-lg">Users</button>
                            </div>
                        </div>
                        <ul v-if="!userList" class="nav nav-tabs list-none border-b-0 pl-0 mb-4" id="tabs-tab" role="presentation">
                            <li v-for="(role, index) in sortedRoles()" :key="role.id" class="flex nav-item justify-between mr-4 text-gray-300">
                                        <a  :href="'#a'+role.id" :id="role.id" @click="setRole(role.id,role.name)" aria-selected="true" :class="{active: index === 0 }" class="nav-link block px-6 py-2 w-full bg-white  cursor-pointer" data-bs-toggle="pill" :data-bs-target="'#a'+role.id" role="tab" :aria-controls="'tabs-'+role.id">
                                        {{role.name}}</a>
                                    <div class="flex justify-between" v-if="roleID === role.id">
                                        <button  v-if="scopeCheck('update_role')" @click="editPerms=!editPerms">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-t3 mr-2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </button>
                                        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-t3 mr-2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                                            </svg>
                                        </button>
                                        <button v-if="scopeCheck('delete_role')" @click="deleteRole(role.id)">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-t3-cancel">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </button>
                                    </div>
                            </li>
                            <div class="p-4">
                            <button v-if="!userList" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" class=" mt-2 py-1.5 text-md text-t3 border border-t3 rounded-md w-full ml-auto">+ New role</button>
                            </div>
                        </ul>
                    </div>
                    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
                        <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                    <h5 v-if="scopeCheck('create_role')" class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                                    Add new role
                                    </h5>
                                    <button type="button" id="roleClose" class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body relative p-4">
                                    <input v-model="addedRole" type="text" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        id="exampleFormControlInput1" placeholder="Role title"/>
                                </div>
                                <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                    <button type="button" class="inline-block font-medium mt-6 border border-t3-cancel py-2 px-4 text-center ml-auto mr-2 text-t3-cancel rounded-md hover:bg-t3-cancel hover:text-white" data-bs-dismiss="modal"> Close</button>
                                    <button @click="addRole" type="button" class="inline-block font-medium mt-6 bg-t3 py-2 px-4 text-center mr-2 text-white rounded-md hover:text-white hover:bg-t3-secondary">Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" w-full col-span-3 mt-4">
                        <div class="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div class="offcanvas-header flex items-center justify-between p-4">
                            <h5 class="offcanvas-title mb-0 leading-normal font-semibold" id="offcanvasRightLabel">{{roleName}}</h5>
                            <div>
                                <button type="button" v-if="scopeCheck('assign_roles')" @click="canvasAdd=!canvasAdd" class="inline-block py-2.5 mr-6 mx-auto font-medium text-t3-teritiary text-base">Edit</button>
                                <button type="button" class="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            
                        </div>
                        <div class="offcanvas-body flex-grow p-4 overflow-y-auto">
                            <div v-for="customer in filteredCustomers" :key="customer.id" class="flex mx-1.5 my-3 items-center justify-between">
                                <p>{{customer.full_name}}</p>
                                <p>{{customer.customer_id}}</p>
                                <button type="button" v-if="scopeCheck('assign_roles')" @click="deleteRoleFromcustomer(customer.id)" class="inline-block px-3 font-medium text-t3-cancel text-3xl mr-1.5">-</button>
                            </div>
                            <div v-if="canvasAdd">
                                <select  v-model="customerRoled" class="my-8  h-12  form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                                <option disabled :value="null">Select User</option>
                                <option v-for="customer in staffOnly"  :value="customer.id" :key="customer.id">{{customer.full_name}}</option>
                                </select> 
                                <button type="button"
                                @click="addRoleTOCustomer" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                Add Role to User
                                </button>
                            </div> 
                        </div>
                        </div>

                        <div  class="flex w-full tab-content  col-span-3 mt-4" id="tabs-tabContent">
                            <div v-for="(role, index) in roles" :key="index" class="  tab-pane fade" :class="{ 'active show': index === 0 }" :id="'a'+role.id" role="tabpanel" aria-labelledby="tabs-text-tab">
                                <div class="flex justify-between items-center">
                                    <span>{{role.name}} has the following permissions</span>
                                </div>
                                <div v-for="(moduler,index) in modules" :key="index" class="p-4">
                                    <p class="text-t3 text-lg mb-3">{{moduler.name}}</p>
                                    <div v-for="(scope,index) in moduler.scope_set" :key="index" class="form-check mb-3">
                                        <input  @change="addPerm(role.id,role.name,role.scopes,scope.id)" :checked="checkPerm(role.scopes, scope.id )" class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" :disabled="editPerms" id="flexCheckDefault">
                                        <label class="form-check-label inline-block text-gray-500" for="flexCheckDefault">
                                        {{scope.name}}
                                        <p>{{scope.description}}</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="userList" class="bg-white grid gap-4 grid-cols-1 sm:grid-cols-4" style="min-height: calc(80vh - 148px);">
                    <div class="border-r border-gray-100">
                        <div class="flex p-4">
                            <div class="bg-gray-200 w-full flex justify-center rounded-md">
                                <button @click="userList=false" :class="!userList ? 'text-t3 bg-white py-1 px-2  2xl:px-8 my-1 rounded-md text-center' : 'text-gray-700 py-1  px-2 2xl:px-8'" class="2xl:text-lg">Roles</button>
                                <button @click="userList=true" :class="userList ? 'text-t3 bg-white py-1 px-2  2xl:px-8 my-1 rounded-md text-center' : 'text-gray-300'" class="2xl:text-lg">Users</button>
                            </div>
                        </div>
                        <div class=" px-14 py-3 form-switch">
                            <input v-if="scopeCheck('update_role')" v-model="editPerms" :true-value="false" :false-value="true" class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" id="flexSwitchCheckChecked">
                            <label class="form-check-label inline-block text-gray-800" for="flexSwitchCheckChecked">Edit Permissions</label>
                        </div>
                        <ul v-if="userList" class="nav nav-tabs list-none border-b-0 pl-0 mb-4" id="tabs-tab2" role="presentation">
                            <li v-for="(customer, index) in sortedCustomers()" :key="customer.id" class="nav-item text-gray-300">
                                <a  v-if="customer.scopes" :href="'#a'+customer.id" :id="customer.id" @click="setRole(customer.id,customer.full_name)" aria-selected="true" :class="{active: index === 0 }" class="nav-link block px-6 py-2 w-full bg-white  cursor-pointer" data-bs-toggle="pill" :data-bs-target="'#a'+customer.id" role="tab" :aria-controls="'tabs-'+customer.id">
                                {{customer.full_name}}</a>
                            </li>
                            <!-- <button type="button" class="inline-block px-6 py-2.5 font-medium mt-12 text-t3-teritiary text-base mr-1.5">Toggle Edit Perms</button> -->
                        </ul>
                    </div>
                    <div class=" w-full col-span-3 mt-4">
                    <div  class="flex w-full tab-content  col-span-3 mt-4" id="tabs-tabContent">
                    <div v-for="(customer, index) in sortedCustomers()" :key="index" class="  tab-pane fade" :class="{ 'active show': index === 0 }" :id="'a'+customer.id" role="tabpanel" aria-labelledby="tabs-text-tab">
                        <div class="flex justify-between items-center">
                            <span>{{customer.full_name}} has the following permissions</span>
                        </div>
                        <div v-for="(moduler,index) in modules" :key="index" class="p-4">
                            <p class="text-t3 text-lg mb-3">{{moduler.name}}</p>
                            <div v-for="(scope,index) in moduler.scope_set" :key="index" class="form-check mb-3">
                                <input  @change="addPermCustomer(customer.id,customer.scopes,scope.id)" :checked="checkPerm(customer.scopes, scope.id )" class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" :disabled="editPerms" id="flexCheckDefault">
                                <label class="form-check-label inline-block text-gray-500" for="flexCheckDefault">
                                   {{scope.name}}
                                   <p>{{scope.description}}</p>
                                </label>
                            </div>
                        </div>
                    </div>

                    </div>
                    </div>
                </div>
            </div>
            <!-- Roles & Permissions End -->

            <!-- Tasks Control Start -->
            <div class="tab-pane fade show" id="tabs-tasks" role="tabpanel" aria-labelledby="tabs-tasks-tab">
                <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="overflow-hidden">
                        <table class="min-w-full">
                        <thead class="border-b">
                            <tr>
                            <th scope="col" class="text-base font-medium text-t3 px-6 py-4 text-left">Task Name</th>
                            <th scope="col" class="text-base font-medium text-t3 px-6 py-4 text-left">Questions Type</th>
                            <th scope="col" class="text-base font-medium text-t3 px-6 py-4 text-left">Questions Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-b" v-for="task in sortedTask()" :key="task.id">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{task.name}}</td>
                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap w-1/3">
                                <input disabled type="text" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="exampleFormControlInput1" :placeholder="task.type"/>   
                            </td>
                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap w-1/3">
                                <input disabled type="text" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="exampleFormControlInput1" :placeholder="task.questions"/>  
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <!-- Tasks Control End -->



            <!-- Addresses Control Start -->
            <div class="tab-pane fade show" id="tabs-addresses" role="tabpanel" aria-labelledby="tabs-addresses-tab">
                <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="overflow-hidden">
                        <div class="flex justify-end">
                            <p class="text-t3 cursor-pointer hover:text-t3-teritiary"  v-if="scopeCheck('edit_settings') && addingAdd === false" @click="addingAdd= true">+ Add</p>
                            <p class="text-t3-cancel cursor-pointer"  v-if="addingAdd === true"  @click="addingAdd= false">Cancel</p>
                        </div>
                        <table class="min-w-full">
                        <thead class="border-b">
                            <tr>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left">Address Name</th>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left w-1/2">Address Location</th>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left "  v-if="scopeCheck('edit_settings')">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-b" v-for="(location,index) in addresses" :key="location.id">                            
                            <td class="py-4 ">
                                <input v-if="listOfAddresses[index]" v-model="listOfAddresses[index].name" :disabled="addressUpdating !== location.id " :class="{'border-none':addressUpdating !== location.id}" type="text" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="exampleFormControlInput1" :placeholder="location.name"/>   
                            </td>
                            <td class="py-4 ">
                                <h1 class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white border-none bg-clip-padding " >{{location.address}}</h1>
                                
                                </td>

                            <td class="pl-6 py-4"  v-if="scopeCheck('edit_settings')">
                                <span class=" text-t3-teritiary cursor-pointer" v-if="addressUpdating !== location.id" @click="addressUpdating = location.id" >Edit</span><span v-if="addressUpdating === location.id" class="text-t3-secondary cursor-pointer" @click="updateAddress(location.id,listOfAddresses[index])" >Submit</span><span class=" text-t3-cancel ml-5 cursor-pointer" v-if="addressUpdating === location.id" @click="addressUpdating = null" >Cancel</span> <span v-if="addressUpdating !== location.id" @click="deleteAddress(location.id)" class="ml-5 text-t3-cancel cursor-pointer">Delete</span>
                            </td>
                            </tr>
                            <tr v-if="addingAdd" class="border-b">                            
                            <td class="p-4">
                                <input type="text" v-model="addressToSend.name" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Add Address Name"/>
                            </td>
                            <td class="p-4">
                                <input type="text" v-model="addressToSend.address" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Add Address Location"/>
                            </td>
                            <td class="pl-6 py-4"> <span @click="addAddress()" class="ml-5 text-t3-secondary cursor-pointer" href="">Submit</span></td>
                            
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <!-- Addresses Control End -->

            
            
            <!-- Organization & Discounts Control Start -->
            <div class="tab-pane fade show" id="tabs-discount" role="tabpanel" aria-labelledby="tabs-discount-tab">
                <div class="p-3">
                    <div class="flex justify-between">
                    <h2 class="font-bold text-t3">Organizations</h2>
                    <p class="text-t3 cursor-pointer hover:text-t3-teritiary" v-if="scopeCheck('edit_settings') && !addingOrg" @click="addingOrg= true" >+ Add</p>
                    <p class="text-t3-cancel cursor-pointer"  v-if="addingOrg"  @click="addingOrg= false">Cancel</p>
                    </div>
                    <table class="min-w-full">
                        <thead class="border-b">
                            <tr>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left">Name</th>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left w-1/6" v-if="scopeCheck('edit_settings')">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                             <tr v-if="addingOrg" class="border-b">                            
                            <td class=" py-4">
                                <input type="text" v-model="organizationToSend.organization_name" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Add Organization Name"/>
                            </td>
                            <td class="pl-6 py-4"> <span @click="addOrganization" class="ml-5 text-t3-secondary cursor-pointer" href="">Submit</span></td>
                            
                            </tr>
                            <tr v-for="(organization,index) in organizations"  :key="organization.id" class="border-b">                            
                            <td class=" py-4">
                                <input type="text" :class="{'border-none':editOrg !== organization.id}" v-model="listOfOrganizations[index].organization_name" :disabled="editOrg !== organization.id" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" :placeholder="organization.organization_name"/>
                            </td>
                            <td class="pl-6 py-4" v-if="scopeCheck('edit_settings')"> <span class=" text-t3-teritiary cursor-pointer" v-if="editOrg !== organization.id" @click="editOrg = organization.id" >Edit</span><span v-if="editOrg === organization.id" class=" text-t3-secondary cursor-pointer" @click="updateOrganization(organization.id,listOfOrganizations[index])" >Submit</span><span class=" text-t3-cancel ml-5 cursor-pointer" v-if="editOrg === organization.id" @click="editOrg = null" >Cancel</span> <span v-if="editOrg !== organization.id" @click="deleteOrganization(organization.id)" class="ml-5 text-t3-cancel cursor-pointer">Delete</span> </td>
                            </tr>
                           
                        </tbody>
                        </table>
                </div>

                <div class="p-3 mt-9">
                    <div class="flex justify-between">
                    <h2 class="font-bold text-t3">Discount Rates</h2>
                    <p class="text-t3 cursor-pointer hover:text-t3-teritiary" v-if="scopeCheck('edit_settings') && !addingDisc" @click="addingDisc= true">+ Add</p>
                    <p class="text-t3-cancel cursor-pointer"  v-if="addingDisc"  @click="addingDisc= false">Cancel</p>
                    </div>
                    <table class="min-w-full">
                        <thead class="border-b">
                            <tr>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left">Name</th>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left">Price</th>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left">Rate</th>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left">For Organization</th>
                            <th scope="col" class=" font-medium text-t3 px-6 py-4 text-left" v-if="scopeCheck('edit_settings')">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="addingDisc" class="border-b">                            
                            <td class=" py-4">
                                <input type="text" v-model="discountToSend.name" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Discount Name"/>
                            </td>
                            <td class=" py-4">
                                <input v-model="discountToSend.discounted_price" @input="setRate()" type="text" class="text-center form-control inline-block w-32 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="rates" placeholder="Your Price"/>
                                <p class="form-label inline-block mx-auto ml-2 text-sm text-gray-400">AED</p>
                               
                            </td>
                            <td class=" py-4">
                                <input v-model="discountToSend.discount_rate" @input="setPrice()" type="text" class="text-center form-control inline-block w-32 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="rates" placeholder="Your Rate"/>
                                <p class="form-label inline-block mx-auto ml-2 text-sm text-gray-400">%</p>
                            </td>
                            <td class=" py-4">
                                <select v-model="discountToSend.organization" id="compiance" class="disdrop form-control block p-1.5 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                                    <option :value="null" disabled="">Assign to Organization</option>
                                    <option v-for="organization in organizations"  :key="organization.id" :value="organization.id">{{organization.organization_name}}</option>

                                </select>
                            </td>
                            <td class="pl-6 py-4"> <span @click="addDiscount" class="ml-5 text-t3-secondary cursor-pointer" >Submit</span> </td>
                            </tr>
                            <tr v-for="(discount,index) in discounts"  :key="discount.id" class="border-b">                            
                            <td class=" py-4">
                                <input type="text" :class="{'border-none':editDisc !== discount.id}" :disabled="editDisc !== discount.id"  v-model="listOfDiscounts[index].name" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Discount Name"/>
                            </td>
                            <td class="  py-4">
                                <input type="text" :disabled="editDisc !== discount.id" @input="setRateE(index, listOfDiscounts[index].discounted_price)" :class="{'border-none':editDisc !== discount.id}" v-model="listOfDiscounts[index].discounted_price" class="form-control mx-auto inline-block w-32 text-center px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Your Price"/>
                                <p class="form-label inline-block mx-auto ml-2 text-sm text-gray-400">AED</p>
                            </td>
                            <td class="  py-4">
                                <input type="text" :disabled="editDisc !== discount.id"  @input="setPriceE(listOfDiscounts[index].discount_rate, index)" :class="{'border-none':editDisc !== discount.id}" v-model="listOfDiscounts[index].discount_rate" class="form-control mx-auto inline-block w-32 text-center px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Your Rate"/>
                                <p class="form-label inline-block mx-auto ml-2 text-sm text-gray-400">%</p>
                            </td>
                            <td class=" py-4">
                                <select v-model="listOfDiscounts[index].organization" :disabled="editDisc !== discount.id" id="compiance" :class="{'border-none disdrop':editDisc !== discount.id}" class=" form-control block p-1.5 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                                    <option :value="null" disabled="">No Organization</option>
                                    <option v-for="organization in organizations"  :key="organization.id" :value="organization.id">{{organization.organization_name}}</option>

                                </select>
                            </td>
                            <td class="pl-6 py-4" v-if="scopeCheck('edit_settings')"> <span class=" text-t3-teritiary cursor-pointer" v-if="editDisc !== discount.id" @click="editDisc = discount.id" >Edit</span><span v-if="editDisc === discount.id" class=" text-t3-secondary cursor-pointer" @click="updateDiscount(discount.id,listOfDiscounts[index])" >Submit</span><span class=" text-t3-cancel ml-5 cursor-pointer" v-if="editDisc === discount.id" @click="editDisc = null" >Cancel</span> <span v-if="editDisc !== discount.id" @click="deleteDiscount(discount.id)" class="ml-5 text-t3-cancel cursor-pointer" href="">Delete</span> </td>
                            </tr>
                           
                        </tbody>
                        </table>
                </div>

            </div>
            <!-- License Types Control Start -->
            <div class="tab-pane fade show" id="tabs-license" role="tabpanel" aria-labelledby="tabs-license-tab">

                <div class="p-3">
                    <div class="flex justify-between">
                    <h2 class="font-bold text-t3">License Types</h2>
                    <p class="text-t3 cursor-pointer hover:text-t3-teritiary" v-if="scopeCheck('edit_settings') && !addingTyp" @click="addingTyp= true">+ Add</p>
                    <p class="text-t3-cancel cursor-pointer"  v-if="addingTyp"  @click="addingTyp= false">Cancel</p>
                    </div>
                    <table class="min-w-full">
                        <thead class="border-b">
                            <tr>
                            <th scope="col" class=" font-medium text-t3 px-6  py-4 text-left">Name</th>
                            <th scope="col" v-if="scopeCheck('edit_settings')" class="font-medium text-t3 px-6 py-4 text-left w-1/6">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="addingTyp" class="border-b">                            
                            <td class=" py-4" >
                                <input type="text" v-model="customerTypeToSend.name" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" placeholder="Add License Type Name"/>
                            </td>
                            <td class=" pl-6 py-4"> <span @click="addType" class="ml-5 text-t3-secondary cursor-pointer" href="">Submit</span></td>
                            </tr>
                            <tr v-for="(type,index) in types"  :key="type.id" class="border-b">                            
                            <td class=" py-4">
                                <input type="text" v-model="listOfTypes[index].name" :disabled="editTyp !== type.id" class="form-control block w-72 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="someID" :class="{'border-none':editTyp !== type.id}" :placeholder="type.name"/>
                            </td>
                            <td class="w-min pl-6 py-4" v-if="scopeCheck('edit_settings')"> <span class=" text-t3-teritiary cursor-pointer" v-if="editTyp !== type.id" @click="editTyp = type.id" >Edit</span><span v-if="editTyp === type.id" class=" text-t3-secondary cursor-pointer" @click="updateType(type.id,listOfTypes[index])" >Submit</span><span class=" text-t3-cancel ml-5 cursor-pointer" v-if="editTyp ===type.id" @click="editTyp = null" >Cancel</span> <span v-if="editTyp !== type.id" @click="deleteType(type.id)" class="ml-5 text-t3-cancel cursor-pointer">Delete</span> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</Transition>
</template>

<script>
import ReportServices from '@/services/ReportServices';
import Swal from 'sweetalert2';
import { mapActions, mapGetters, mapState } from 'vuex';
import RolesServices from '../services/RolesServices';
import SettingsServices from '../services/SettingsServices';

    export default {
        data() {
            return {
                userList:false,
                addedRole:null,
                editPerms:true,
                editSet:true,
                editOrg:null,
                editDisc:null,
                editTyp:null,
                addingAdd:false,
                addingOrg:false,
                addingDisc:false,
                addingTyp:false,
                canvasAdd:false,
                roleName: null,
                customerRoled:null,
                roleID:null,
                addressToSend:{
                    name:null,
                    address:null
                },
                organizationToSend:{
                    organization_name:null
                },
                customerTypeToSend:{
                    name:null
                },
                discountToSend:{
                    name:null,
                    discount_rate:null,
                    discounted_price:null,
                    organization:null,
                    current:true
                },
                addressUpdating:null,
                listOfAddresses:[],
                listOfDiscounts:[],
                listOfOrganizations:[],
                listOfTypes:[],
                localSettings:{
                        standard_price:null,
                        assessment_duration: null,
                        prefix: null,
                        weekends:null,
                        time_to_close_assessment: null,
                        allow_customer_to_view_certificate: null,
                        show_sample_before_completion: null,
                        show_reports_to_assessors: null,
                        external_dispute: null,
                        internal_dispute: null,
                        dispute_final_approver:null,
                        allow_customer_to_raise_dispute_within:null,
                }
            }
        },
        computed:{
            ...mapState(
                {
                roles: state => state.Roles.roles,
				scopes: state => state.Roles.scopes,
                modules: state => state.Roles.modules,
                tasks: state => state.Assessments.tasks,
                settings: state => state.Settings.settings,
                addresses: state => state.Settings.addresses,
                discounts: state => state.Settings.discounts,
                organizations: state => state.Settings.organizations,
                types: state => state.Settings.customer_types
                }
            ),
             ...mapGetters('Users', [
        'customerOnly',
        'staffOnly',
        "ELE",
        "OPE"
      ]),
            filteredCustomers(){
                return this.staffOnly.filter(cus=> cus.roles.includes(this.roleID))
            },


        },
        methods:{
            ...mapActions( {
                getstaff: 'Users/getStaff',
				getroles: 'Roles/getAllRoles',
                getscopes: 'Roles/getAllScopes',
                getmodules: 'Roles/getAllModules',
                gettasks: 'Assessments/getAllTasks',
                getsettings: 'Settings/getAllSettings',
                getaddresses: 'Settings/getAllAddresses',
                getorganizations: 'Settings/getAllOrganizations',
                getdiscounts: 'Settings/getAllDiscounts',
                gettypes: 'Settings/getAllCustomerTypes',
			}),
            addAddress(){
                SettingsServices.createAddress(this.addressToSend).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "This address has been added to the list of locations!",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getaddresses().then(()=>{
                        this.listOfAddresses=this.addresses
                        this.addressToSend.name = null
                        this.addressToSend.address = null
                        this.$forceUpdate
                    })
                    
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            updateAddress(id,address){
                SettingsServices.updateAddress(id,{name:address.name, address:address.address} ).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The address now has a new name",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.addressUpdating=null
                    this.getaddresses()
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            deleteAddress(id){
                SettingsServices.deleteAddress(id).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The address has been deleted",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.addressUpdating=null
                    this.getaddresses()
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            addOrganization(){
                SettingsServices.createOrganization(this.organizationToSend).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "This organization has been added to the list.",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                        this.getorganizations().then(()=>{
                            this.organizationToSend.organization_name = null
                        this.listOfOrganizations=this.organizations
                        this.addOrganization=false
                        this.$forceUpdate
                    })
                    
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            updateOrganization(id,organization){
                SettingsServices.updateOrganization(id,organization ).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The organization now has a new name",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.editOrg=null
                    this.getorganizations()
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            setRate(){
                this.discountToSend.discount_rate= Math.round(((this.localSettings.standard_price - this.discountToSend.discounted_price)/this.localSettings.standard_price)*100)
            },
            setPrice(){
                this.discountToSend.discounted_price= this.localSettings.standard_price * this.discountToSend.discount_rate/100

            },
            setRateE(rate,price){
                this.listOfDiscounts[rate].discount_rate= Math.round(((this.localSettings.standard_price - price)/this.localSettings.standard_price)*100)
            },
            setPriceE(rate,price){
                this.listOfDiscounts[price].discounted_price= this.localSettings.standard_price * (100-rate)/100

            },
            deleteOrganization(id){
                SettingsServices.deleteOrganization(id).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The organization is now gone forever.",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getorganizations()
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            addType(){
                SettingsServices.createCustomerType(this.customerTypeToSend).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "This customer type has been added to the list.",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                        this.gettypes().then(()=>{
                            this.customerTypeToSend.name = null,
                        this.listOfTypes=this.types
                        this.addTyp=false
                        this.$forceUpdate
                    })
                    
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            updateType(id,type){
                SettingsServices.updateCustomerType(id,type ).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The customer type now has a new name",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.editTyp=null
                    this.gettypes()
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            deleteType(id){
                SettingsServices.deleteCustomerType(id).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The customer type is now gone forever.",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.gettypes()
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            addDiscount(){
                SettingsServices.createDiscount(this.discountToSend).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "New discount option added.",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getdiscounts().then(()=>{
                        this.discountToSend.current = null
                        this.discountToSend.discount_rate = null
                        this.discountToSend.organization= null
                        this.discountToSend.name= null
                        this.discountToSend.discounted_price= null
                        this.listOfDiscounts=this.discounts
                        this.addDiscount=false
                        this.$forceUpdate
                    })
                    
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            updateDiscount(id,newDisc){
                SettingsServices.updateDiscount(id,newDisc ).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The discount now has a new name",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.editDisc=null
                    this.getdiscounts
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            deleteDiscount(id){
                SettingsServices.deleteDiscount(id).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The discount package is now gone forever.",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getdiscounts()
                    this.$forceUpdate
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            setRole(id,name){
                this.roleName=name
                this.roleID=id
                
            },
            addRoleTOCustomer(){
                let tosend= this.staffOnly.find(cus => cus.id === this.customerRoled).roles
                tosend.push(this.roleID)

                RolesServices.addToCustomer(this.customerRoled,{roles:tosend}).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "This customer has now been assigned a new role!",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getroles()
                    this.getstaff()
                    this.$forceUpdate
                    this.customerRoled=null
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            deleteRoleFromcustomer(id){
            Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Are you sure you want to remove this role from the customer?",
							showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
						}).then((result)=>{
                            if(result.value){
                let tosend= this.staffOnly.find(cus => cus.id  === id).roles
                tosend.splice(tosend.indexOf(this.roleID),1)
                
                RolesServices.addToCustomer(id,{roles:tosend}).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "This customer has now been removed from this role!",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getroles()
                    this.getstaff()
                    this.$forceUpdate
                    this.customerRoled=null
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
                            }
                        })
            },
            updateSettings(){
                
                SettingsServices.updateSettings(this.settings.id, this.localSettings).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The settings have been updated!",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getsettings()
                    this.$forceUpdate
                    this.editSet=true
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            addRole(){
                RolesServices.createRole({name:this.addedRole}).then(()=>{
                    Swal.fire({
							icon: "success",
							title: "Success!",
							text: "This role is in the house right now",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
                    this.getroles()
                    this.$forceUpdate
                    document.getElementById("roleClose").click()
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            deleteRole(id){
                 Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Are you sure you want to remove this role?",
							showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
						}).then((result)=>{
                            if(result.value){
                            RolesServices.deleteRole(id).then(()=>{
                                Swal.fire({
                                        icon: "success",
                                        title: "Success!",
                                        text: "This role has been removed",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                    })
                                this.getroles()
                                this.$router.go
                            }).catch((error)=>{
                                let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                            })
                            }
                            
                            
                        })
               
            },
            addPerm(id,name,scopeL,scopeid){
                let tosend = scopeL
                if(tosend.includes(scopeid)){
                    tosend.splice(tosend.indexOf(scopeid),1)
                }
                else{
                    tosend.push(scopeid)
                }
                RolesServices.updateRole(id,{name:name,scopes:tosend}).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            addPermCustomer(id,scopeL,scopeid){
                let tosend = scopeL
                if(tosend.includes(scopeid)){
                    tosend.splice(tosend.indexOf(scopeid),1)
                }
                else{
                    tosend.push(scopeid)
                }
                RolesServices.addToCustomer(id,{scopes:tosend}).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
            },
            checkPerm(list,scopeid){
                if(list.includes(scopeid))
                {
                    return true
                }
                else{
                    return false
                }
            },
            sort_by_id() {
				return function (elem1, elem2) {
				if (elem1.id < elem2.id) {
					return -1;
				} else if (elem1.id > elem2.id) {
					return 1;
				} else {
					return 0;
				}
				}},
            sortedRoles(){
                return this.roles.sort(this.sort_by_id())
            },
            sortedCustomers(){
                return this.staffOnly.sort(this.sort_by_id())
            },
            sortedTask(){
				return this.tasks.sort(this.sort_by_id())
			},
            scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}

        },
        created(){
            this.getroles().then(()=>{
                if(this.roles){
                    this.roleName=this.sortedRoles()[0].name
                    this.roleID=this.sortedRoles()[0].id
                }
                
            })
            this.getaddresses().then(()=>{
                this.listOfAddresses=this.addresses
            })
            this.getorganizations().then(()=>{
                this.listOfOrganizations=this.organizations
            })
            this.getdiscounts().then(()=>{
                this.listOfDiscounts=this.discounts
            })
            this.gettypes().then(()=>{
                this.listOfTypes=this.types
            })
            this.getscopes()
            this.getmodules()
            this.gettasks()
            this.getstaff()
            this.getsettings().then(() =>{
                this.localSettings = this.settings
            })
        }

    }
</script>

<style>
.nav-tabs .nav-link.active {
    color: #212D63;
    border-color: #00ADEE;
}

.nav-tabs .nav-link {
    color: #9ca4af;
}

.disdrop{
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';
	}
</style>
